<template>
  <div id="container_attaches_and_notes">
    <b-modal
      :id="modal.id"
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <div class="header">
        <div class="header-content">
          <div class="">Arquivos e Anotações</div>
          <Close class="close-icon" @click="closeModal" />
        </div>
      </div>
      <div class="body">
        <div class="body-content">
          <p class="font-weight-bold text-primary pt-2">Arquivos Anexados</p>
          <div class="row flex-row mb-2">
            <div class="col-9 d-flex align-items-center flex-wrap p-2" v-if="budget.files.length">
              <template v-for="file in budget.files">
                <BudgetFileBadge :file="file" :budget="budget" :key="file.id" />
              </template>
            </div>
            <div class="col-9 d-flex align-items-center flex-wrap p-2" v-else>
              Seus anexos aparecerão aqui.
            </div>

            <div class="col d-flex align-content-center flex-wrap justify-content-end">
              <b-button variant="outline-primary" @click="openAttachFileModal">
                Anexar Arquivo
              </b-button>
            </div>
          </div>

          <BudgetNotes v-model="budget" />

        </div>
      </div>
    </b-modal>
    <BudgetAttachmentsModal :budget="budget" />
  </div>
</template>
<script>

import BudgetNotes from "@/modules/SellingPanel/components/BudgetNotes.vue";

export default {
  name: "BudgetAttachmentsAndNotes",
  props: {
    budget: Object
  },

  data() {
    return {
      modal: {
        id: 'attaches_and_notes'
      }
    }
  },
  components: {
    BudgetNotes,
    BudgetFileBadge: () => import('@/modules/SellingPanel/components/Attachments/BudgetFileBadge.vue'),
    BudgetAttachmentsModal: () => import('@/modules/SellingPanel/components/BudgetAttachmentsModal.vue'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modal.id)
    },

    openAttachFileModal() {
      this.$bvModal.show('attachments')
    }
  }
}
</script>


<style scoped lang="scss">
.modal-content {
  .modal-body {
    padding: 0;
    .header {
      border-bottom: 1px solid var(--neutral-200);
      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 24px;
        p {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
        .close-icon {
          fill: var(--neutral-500);
          height: 24px;
          width: 24px;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;

      .body-content {
        display: flex;
        flex-direction: column;

        .container {
          display: flex;
          flex-direction: column;
          padding: 24px;

          .title-body-content {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
          }

          .input-name-file {
            background: #FFFFFF;
            border: 1px solid #C6CEEB;
            box-sizing: border-box;
            border-radius: 8px;
            height: 56px;
            outline: none;
            text-indent: 16px;
            color: var(--type-active);
            margin: 8px 0 24px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
          }

          .file-content {
            display: flex;
            flex-direction: column;

            .title-file-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }

            .file-type {
              display: flex;
              align-items: center;

              .input-file-type {
                height: 24px;
                width: 24px;
                margin-right: 12px;
                box-sizing: border-box;
                border-radius: 100px;
                cursor: pointer;
              }

              p + input {
                margin-left: 24px;
              }

            }
          }
        }

        .show-file {
          border-top: 1px solid #C6CEEB;
          border-bottom: 1px solid #C6CEEB;
        }

        .set-file {
          margin: 24px;
          .set-file-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 300px;
            border: 1px dashed #305BF2;
            border-radius: 8px;

            .set-file-content-text {
              font-weight: 700;
              font-size: 12px;
              line-height: 130%;
            }

            .middle-set-file-content {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin: 12px 0;

              .middle-set-file-content-detail {
                width: 54px;
                height: 2px;
                background: var(--neutral-300);
                border-radius: 16px;
              }

              .middle-set-file-content-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 130%;
                padding: 0 14px;
              }
            }

            .set-file-content-input {
              width: auto;
              height: auto;
              padding: 8px 16px;
              font-weight: 700;
              font-size: 12px;
              line-height: 130%;
              color: var(--neutral-000);
              border-radius: 8px;
              background: var(--blue-700);
              cursor: pointer;
            }

            #set-file-content-input {
              display: none;
            }
          }
        }
      }
    }

    .footer {
      .footer-content {
        display: flex;
        justify-content: right;
        margin: 24px;

        button {
          width: auto;
          height: auto;
          padding: 14px 16px;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          border-radius: 8px;
          background-color: var(--blue-500);
          color: var(--neutral-000);
        }
      }
    }
  }
}

</style>