<template>
  <div id="budget-status-select">
    <multiselect
        v-model="status"
        :options="privateStatus"
        track-by="value"
        label="label"
        :showLabels="false"
        placeholder="Mudar Situação"
        @select="onChangeStatus"
    >
      <template #singleLabel="props">
        <div class="option__title d-flex align-items-center justify-content-center" :style="props.option.style">
          <div>
            {{ props.option.label }}
          </div>
          <div class="chevron">
            <ChevronDown />
          </div>
        </div>
      </template>
      <template #option="props">
        <div class="option__desc">
          <span class="option__title" :style="props.option.style">{{ props.option.label }}</span>
        </div>
      </template>
    </multiselect>
  </div>

</template>
<script>

import { privateStatus, healthPlanStatus, requestTypes } from "@/modules/SellingPanel/config/budget"
export default {
  name: "BudgetStatusSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  props: {
    budget: {
      type: Object
    }
  },
  data() {
    return {
      requestTypes,
      privateStatus: [],
      status: healthPlanStatus.get(this.budget.status)
    }
  },
  mounted() {
    if(this.budget.health_plan_id.type === 'particular') {

      privateStatus.forEach((cfg, value) => {
        this.privateStatus.push({ label: cfg.label, value: value, style: cfg.style})
      })
    }
    else {
      healthPlanStatus.forEach((cfg, value) => {
        this.privateStatus.push({ label: cfg.label, value: value, style: cfg.style})
      })
    }
  },
  methods: {
    onChangeStatus(status) {
      if(this.budget.status !== status.value) {
        const l = this.$loading.show()
        this.api.updateBudgetStatus(this.budget.id, status.value).then(res => {
          this.$toast.success(`Status atualizado de ${healthPlanStatus.get(this.budget.status).label} para ${status.label}`)
          this.budget.status = status.value
        })
        .catch(error => {
          console.error(error)
          this.$toast.error('Erro ao tentar mudar Status.')
        })
        .finally(() => l.hide())
      }
    }
  }
}
</script>


<style scoped lang="scss">
 .option__title {
   padding: 7px 10px;
   border-radius: 15px;
 }

 #budget-status-select {
   .multiselect__single {
     padding: 0 !important;
   }
   .chevron {
     position: static !important;
     transform: none;
   }
 }
</style>