<template>
  <b-form-group class="form-group">
    <Search id="search" />
    <b-form-input
        class="patient-search-input"
        autocomplete="off"
        debounce="500"
        placeholder="Buscar por Paciente"
        v-model="value.name"
    />
  </b-form-group>
</template>

<script>
export default {
  name: "SearchPatient",
  props: {
    value: Object
  },
  components: {
    Search: () => import('@/assets/icons/search.svg'),
  }
}
</script>

<style scoped lang="scss">
#search {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 7px;
  left: 12px;
  stroke: var(--neutral-400);
}
.patient-search-input {
  text-indent: 20px;
}
</style>