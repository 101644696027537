import { render, staticRenderFns } from "./SearchPatient.vue?vue&type=template&id=45381bda&scoped=true"
import script from "./SearchPatient.vue?vue&type=script&lang=js"
export * from "./SearchPatient.vue?vue&type=script&lang=js"
import style0 from "./SearchPatient.vue?vue&type=style&index=0&id=45381bda&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45381bda",
  null
  
)

export default component.exports