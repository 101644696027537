export const datePickerConfig = {
    lang: {
        formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
    },
    format: 'dddd[,] DD [de] MMMM',
    'value-type': 'YYYY-MM-DD HH:mm:ss'
}

export const healthPlanTypes = new Map([
    ['particular', { name: 'Particular', value: 'particular'}],
    ['private_health', { name: 'Convênio', value: 'private_health'}],
])

export const budgetFilters = {
    period: {
        field: null,
        from: null,
        to: null
    },
    requester_doctor_id: null,
    health_plan_id: null,
    item_id: null,
    request_types: [],
    status: [],
    patient: {
        name: null
    },
}