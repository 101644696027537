
/**
 * Note Struct
 * @type {Object<{description: string, person_id: uuid,budget_id: uuid, created_at: datetime}>}
 */
const note = {
    description: '',
    person_id: null,
    budget_id: null
}

const noteValidations = {
    description: {
        expr: ['string', ['length', '>=', 2]],
        msg: ['Por favor, informe uma Descrição válida', 'Descrição Inválida']
    },

}

export {
    note,
    noteValidations,
}