<template>
  <b-row id="container_notes">
    <b-col class="col-12">
      <div class="card">
        <div class="card-title d-flex justify-content-between p-2">
          <div class="note-title">Anotações</div>
          <div class="chevron">
            <ChevronDown
              @click="visible = !visible"
              :class="{ rotateUp: visible, rotateDown:!visible }"
            />
          </div>
        </div>

        <div class="form-notes px-2 pb-3">
          <div class="row">
            <div class="col-10">
              <b-form-input
                  id="annotation"
                  v-model="note.description"
                  autocomplete="off"
                  placeholder="Inserir Anotação..."
                  @keyup.enter="add"
              />
            </div>
            <div class="col-2 d-flex justify-content-end">
              <b-button
                  variant="primary"
                  @click="add"
              >
                Adicionar Nota
              </b-button>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="divider"></div>
          <b-collapse :visible="visible" :id="`collapse_notes`" class="collapse-wrapper" v-if="value.notes.length">
            <div class="row" v-for="(note,i) in value.notes" :key="i">
              <div class="col-12 font-weight-bold my-2 note-header">
                {{note.person.name}} <span class="note-time">{{note?.created_at | getTime }}</span>
              </div>
              <div class="col-12 note-description">
                {{note.description}}
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {getCurrentUser} from "@/utils/localStorageManager"
import {note as defaultNote} from "@sp/config/note"
import moment from "moment"

export default {
  name: "BudgetNotes",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },

  props: {
    value: {
      type: Object
    }
  },

  data() {
    return {
      note: {...defaultNote},
      user: getCurrentUser(),
      visible: false,
    }
  },

  mounted() {
    if(this.value.notes.length) {
      this.visible = true
    }
  },

  methods: {
    validate() {
      if(this.note.description.length < 2) {
        this.$toast.error('Por favor, insira uma Anotação válida')
        return false
      }
      return true
    },
    add() {

      if(! this.validate()) return

      const note = {...this.note}
      note.person_id = this.user.id
      note.person = { name: this.user.name }
      if(this.value.id) {
        note.budget_id = this.value.id
      }

      if(note.budget_id) {

        const l = this.$loading.show()
        this.api.createBudgetNote(note).then( res => {
          const note = res.data.note
          note.person = { name: this.user.name }
          this.value.notes.unshift(note)
          this.$toast.success('Anotação adicionada com Sucesso')
          if(! this.visible) {
            this.visible = true
          }
        })
        .catch(error => {
          console.error(error)
          this.$toast.error('Não foi possível adicionar a Anotação, por favor, tente novamente ou contate nosso Suporte.')
        })
        .finally(() => {
          l.hide()
          this.note = {...defaultNote}
        })
      }
      else {
        this.value.notes.unshift(note)
        this.$toast.success('Anotação adicionada com Sucesso')
        if(! this.visible) {
          this.visible = true
        }
      }
    }
  },

  filters: {
    getTime(val) {
      if(val) {
        const time = moment(val)
        return ` às ${time.format('HH[h]mm')} de ${time.format('DD/MM/YYYY')}`
      }

      return ' neste momento'
    }
  }
}
</script>

<style scoped lang="scss">
.note-title {
  color: var(--neutral-700);
  font-weight: bold;
}
#container_notes {
  .chevron {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .note-header {
    color: var(--neutral-700);
  }
  .note-time {
    color: var(--neutral-500);
  }
  .note-description {
    color: var(--neutral-700)
  }
}
.rotateUp {
  animation: rotateUp .5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.rotateDown {
  animation: rotateDown .5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes rotateUp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateDown {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>